import React, { useState } from 'react'
import Auth from '@aws-amplify/auth'
import { connect } from 'react-redux'
import { Form, Segment, Button } from 'semantic-ui-react'

function SignedIn({ username, setForm }) {
  const [loading, setLoading] = useState(false)

  function signIn() {
    setForm('redirect')
  }

  async function signOut() {
    setLoading(true)
    localStorage.removeItem('token')
    await Auth.signOut({ global: true })
    location.reload()
  }

  return (
    <Segment raised>
      <Form loading={loading}>
        <Button primary fluid onClick={signIn}>Continuar como {username}</Button>
        <Button fluid onClick={signOut} style={{ marginTop: '16px' }}>Entrar com outra conta</Button>
      </Form>
    </Segment>
  )
}

export default connect(
  store => { return { username: store.user.username } },
  dispatch => {
    return {
      setForm: form => dispatch({ type: 'UPDATE', data: { form } })
    }
  }
)(SignedIn)
