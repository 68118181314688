import React from 'react'
import Auth from '@aws-amplify/auth'
import { connect } from 'react-redux'
import { Button, Form, Segment } from 'semantic-ui-react'
import { Formik, Field } from 'formik'
import * as yup from 'yup'

const schema = yup.object().shape({
  currentPassword: yup.string().min(6, 'A senha deve ter pelo menos 6 caracteres.').required('Informe a senha atual.'),
  newPassword: yup.string().min(6, 'A senha deve ter pelo menos 6 caracteres.').required('Informe uma nova senha.'),
  confirmPassword: yup.string().required('Informe uma nova senha.').oneOf([yup.ref('newPassword')], 'Confirmação de senha não coincide com a nova senha informada.')
})

function ResetPassword({ username, setForm, setMessage }) {
  async function onSubmit(values, actions) {
    try {
      const user = await Auth.signIn(username, values.currentPassword)
      if (user) {
        await Auth.completeNewPassword(user, values.newPassword)
        setMessage(['positive', 'Senha redefinida com sucesso.'])
        setForm('login')
      }
    } catch (err) {
      if (err.code == 'CodeMismatchException') {
        setMessage(['negative', 'Código inválido.'])
      } else if (err.code == 'NotAuthorizedException') {
        setMessage(['negative', 'Senha atual inválida.'])
      } else {
        setMessage(['negative', err.code])
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <Formik initialValues={{ currentPassword: '', newPassword: '', confirmPassword: '' }} validationSchema={schema} onSubmit={onSubmit}>
      {({ isSubmitting, handleReset, handleSubmit }) => (
        <Segment raised>
          <Form loading={isSubmitting} onReset={handleReset} onSubmit={handleSubmit}>
            <Field name='currentPassword' render={({ field, form: { errors, touched } }) => (<Form.Input {...field} fluid icon='lock' iconPosition='left' placeholder='Senha atual' type='password' required error={touched.currentPassword && errors.currentPassword} />)} />
            <Field name='newPassword' render={({ field, form: { errors, touched } }) => (<Form.Input {...field} fluid icon='lock' iconPosition='left' placeholder='Nova senha' type='password' required error={touched.newPassword && errors.newPassword} />)} />
            <Field name='confirmPassword' render={({ field, form: { errors, touched } }) => (<Form.Input {...field} fluid icon='lock' iconPosition='left' placeholder='Confirmar senha' type='password' required error={touched.confirmPassword && errors.confirmPassword} />)} />
            <Button color='teal' fluid type='submit'>Salvar</Button>
          </Form>
        </Segment>
      )}
    </Formik>
  )
}

export default connect(
  store => { return { username: store.user.username } },
  dispatch => {
    return {
      setMessage: message => dispatch({ type: 'UPDATE', data: { message } }),
      setForm: form => dispatch({ type: 'UPDATE', data: { form } })
    }
  }
)(ResetPassword)
