import React from 'react'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import gql from 'graphql-tag'
import qs from 'query-string'
import { ApolloContext } from 'react-apollo'
import { Button, Form, Segment } from 'semantic-ui-react'
import { Formik, Field } from 'formik'
import * as yup from 'yup'

class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: !!(process.browser && qs.parse(location.search).code)
    }

    this.schema = yup.object().shape({
      username: yup.string().required().email(),
      password: yup.string().required()
    })
  }
  
  async signIn() {
    try {
      this.setState({ loading: true })
      const { accessToken } = await Auth.currentSession()
      const { client } = this.context

      if (accessToken.payload.scope.indexOf('openid') >= 0) {
        const { data: { authenticate } } = await client.mutate({
          mutation: gql`
            mutation Authenticate($environmentId:Int! $cognitoToken:String!) {
              authenticate(input:{
                environmentId: $environmentId
                cognitoToken: $cognitoToken
              }) {
                jwtToken
              }
            }
          `,
          variables: {
            environmentId: parseInt(localStorage.getItem('env')),
            cognitoToken: accessToken.jwtToken
          }
        })

        if (authenticate && authenticate.jwtToken) {
          localStorage.setItem('token', authenticate.jwtToken)
          this.props.setForm('redirect')
        } else {
          this.props.setMessage(['negative', 'Conta não cadastrada.'])
        }
      }
    } finally {
      this.setState({ loading: false })
    }
  }

  async authCallback({ payload: { event } }) {
    switch (event) {
      case 'signIn':
        this.setState({ loading: true })
        Auth.currentAuthenticatedUser().then(() => {
          this.signIn()
        }).catch(() => {
          this.setState({ loading: false })
        })
        break
    }
  }

  async onSubmit(values, actions) {
    const { setCurrentUser, setMessage, setForm } = this.props
    const { client } = this.context

    try {
      const user = await Auth.signIn(values)

      if (user.challengeName == 'NEW_PASSWORD_REQUIRED') {
        setCurrentUser({ username: values.username })
        setMessage(['positive', 'Defina uma nova senha de acesso.'])
        setForm('resetPassword')

      } else {
        const { data: { authenticate } } = await client.mutate({
          mutation: gql`
              mutation Authenticate($environmentId:Int! $login:String! $password:String!) {
                authenticate(input:{
                  environmentId: $environmentId
                  login: $login
                  password: $password
                }) {
                  jwtToken
                }
              }
            `,
          variables: {
            environmentId: parseInt(localStorage.getItem('env')),
            login: values.username,
            password: values.password
          }
        })

        if (authenticate && authenticate.jwtToken) {
          localStorage.setItem('token', authenticate.jwtToken)
          setForm('redirect')
        } else {
          setMessage(['negative', 'Conta não cadastrada.'])
        }
      }
    } catch (err) {
      if (err.code == 'PasswordResetRequiredException') {
        await Auth.forgotPassword(values.username)
        setCurrentUser({ username: values.username })
        setMessage(['positive', 'Defina uma nova senha de acesso.'])
        setForm('recoverPassword')

      } else if (err.code == 'NotAuthorizedException' || err.code == 'UserNotFoundException') {
        setMessage(['negative', 'Usuário e/ou senha inválidos.'])
        actions.setFieldValue('password', '', false)

      } else {
        setMessage(['negative', err.code])
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  federatedLogin(provider) {
    this.setState({ loading: true })
    Auth.federatedSignIn({ provider })
  }

  componentDidMount() {
    Hub.listen('auth', this.authCallback.bind(this))
  }

  componentWillUnmount() {
    Hub.remove('auth', this.authCallback.bind(this))
  }

  render() {
    return (
      <Formik initialValues={this.props.currentUser} validationSchema={this.schema} onSubmit={(values, actions) => this.onSubmit(values, actions)}>
        {({ isSubmitting, handleReset, handleSubmit }) => (
          <Segment raised>
            <Form loading={this.state.loading || isSubmitting} onReset={handleReset} onSubmit={handleSubmit}>
              <Field name='username' render={({ field, form: { errors, touched } }) => (<Form.Input {...field} type='email' fluid icon='mail' iconPosition='left' placeholder='E-mail' required error={!!(touched.username && errors.username)} />)} />
              <Field name='password' render={({ field, form: { errors, touched } }) => (<Form.Input {...field} type='password' fluid icon='lock' iconPosition='left' placeholder='Senha' required error={!!(touched.password && errors.password)} />)} />
              <Button color='teal' fluid type='submit'>Entrar</Button>
              <div style={{margin:'16px 0 32px 0'}}>
                <a href='javascript:;' onClick={() => this.props.setForm('recover')}>Esqueci minha senha</a>
              </div>
              <Button fluid labelPosition='left' type='button' style={{ marginTop: '8px' }} color='facebook' icon='facebook f' content='Entrar com Facebook' onClick={this.federatedLogin.bind(this, 'Facebook')} />
              <Button fluid labelPosition='left' type='button' style={{ marginTop: '8px' }} color='google plus' icon='google plus g' content='Entrar com Google' onClick={this.federatedLogin.bind(this, 'Google')} />
              <Button fluid labelPosition='left' type='button' style={{ marginTop: '8px' }} icon='microsoft' content='Entrar com Office da MOL' onClick={this.federatedLogin.bind(this, 'Microsoft')} />
            </Form>
          </Segment>
        )}
      </Formik>
    )
  }
}

Login.contextType = ApolloContext

export default connect(
  store => {
    return {
      currentUser: store.user
    }
  },
  dispatch => {
    return {
      setCurrentUser: user => dispatch({ type: 'user/UPDATE', user }),
      setMessage: message => dispatch({ type: 'UPDATE', data: { message } }),
      setForm: form => dispatch({ type: 'UPDATE', data: { form } }),
    }
  }
)(Login)
