import React from 'react'
import { connect } from 'react-redux'
import { ApolloContext } from 'react-apollo'
import gql from 'graphql-tag'
import { Segment, Label, Image, List, Loader } from 'semantic-ui-react'

const GET_USER = gql`{ user { environmentId accountId credentialId } }`

const GET_TOKEN = gql`mutation Authorize($accountId:UUID!) { authorize(input:{accountId:$accountId}) { jwtToken } }`

const GET_ACCOUNTS = gql`
  query Accounts($environmentId:Int! $credentialId:UUID!) {
    accounts(filter:{
      environmentId:{equalTo:$environmentId}
      isArchived:{equalTo:false}
      accountsCredentials:{
        some:{
          credentialId:{equalTo:$credentialId}
          isArchived:{equalTo:false}
        }
      }
    }) {
      nodes {
        id
        name
        imageUrl
      }
    }
  }
`

class Redirect extends React.Component {
  constructor(props) {
    super(props)
    this.formRef = React.createRef()
    this.state = {
      showForm: false,
      token: '',
      accounts: [],
      loading: false
    }
  }

  async componentDidMount() {
    const { client } = this.context
    const { data: { user } } = await client.query({ query: GET_USER })
    const { data: { accounts } } = await client.query({
      query: GET_ACCOUNTS,
      variables: {
        environmentId: user.environmentId,
        credentialId: user.credentialId
      }
    })

    if (user.accountId && accounts.nodes.length == 1) {
      this.setState({
        token: localStorage.getItem('token'),
        showForm: true
      })
    } else {
      this.setState({ accounts: accounts.nodes })
    }
  }

  componentDidUpdate() {
    if (this.state.showForm)
      this.formRef.current.submit()
  }

  async setAccount(id) {
    try {
      this.setState({ loading: true })
      const { client } = this.context
      const { data: { authorize: { jwtToken } } } = await client.mutate({
        mutation: GET_TOKEN,
        variables: { accountId: id }
      })
      localStorage.setItem('token', jwtToken)
      this.setState({
        token: jwtToken,
        showForm: true
      })

    } catch (e) {
      console.log(e)
      this.setState({ loading: false })
      alert('Ocorreu um erro inesperado.')
    }
  }

  render() {
    return (
      <>
        {this.state.showForm && <form ref={this.formRef} action={sessionStorage.getItem('redirect')} method={sessionStorage.getItem('method')}>
          <input type='hidden' name='token' value={this.state.token} />
        </form>}

        {this.state.loading && <Loader active={true} />}

        {!this.state.showForm && !this.state.loading && this.state.accounts.length > 0 && <Segment textAlign='left'>
          <Label ribbon color='blue'>Selecione uma conta</Label>
          <br />
          <List divided>
            {this.state.accounts.map(account => {
              return <List.Item key={account.id} style={{cursor:'pointer'}} onClick={() => this.setAccount(account.id)}>
                <List.Content>
                  <Image avatar src={account.imageUrl || require('../../images/camera.jpg')} />&nbsp;
                  {account.name}
                </List.Content>
              </List.Item>
            })}
          </List>
        </Segment>}
      </>
    )
  }
}

Redirect.contextType = ApolloContext

export default Redirect

