import React from 'react'
import Auth from '@aws-amplify/auth'
import { connect } from 'react-redux'
import { Button, Form, Segment } from 'semantic-ui-react'
import { Formik, Field } from 'formik'
import * as yup from 'yup'

const schema = yup.object().shape({
  code: yup.string().matches(/\d+/, { message: 'Informe um código válido.', excludeEmptyString: true }).required('Informe o código recebido por e-mail.'),
  password: yup.string().min(6, 'A senha deve ter pelo menos 6 caracteres.').required('Informe uma nova senha.'),
  confirmPassword: yup.string().required('Informe uma nova senha.').oneOf([yup.ref('password')], 'Confirmação de senha não coincide com a nova senha informada.')
})

function RecoverPassword({ username, setForm, setMessage }) {
  async function onSubmit(values, actions) {
    try {
      await Auth.forgotPasswordSubmit(username, values.code, values.password)
      setMessage(['positive', 'Senha redefinida com sucesso.'])
      setForm('login')
    } catch (err) {
      if (err.code == 'CodeMismatchException') {
        setMessage(['negative', 'Código inválido.'])
      } else {
        setMessage(['negative', err.code])
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <Formik initialValues={{ code: '', password: '', confirmPassword: '' }} validationSchema={schema} onSubmit={onSubmit}>
      {({ isSubmitting, handleReset, handleSubmit }) => (
        <Segment raised>
          <Form loading={isSubmitting} onReset={handleReset} onSubmit={handleSubmit}>
            <Field name='code' render={({ field, form: { errors, touched } }) => (<Form.Input {...field} fluid icon='terminal' iconPosition='left' placeholder='Código recebido por e-mail' required error={touched.code && errors.code} />)} />
            <Field name='password' render={({ field, form: { errors, touched } }) => (<Form.Input {...field} fluid icon='lock' iconPosition='left' placeholder='Nova senha' type='password' required error={touched.password && errors.password} />)} />
            <Field name='confirmPassword' render={({ field, form: { errors, touched } }) => (<Form.Input {...field} fluid icon='lock' iconPosition='left' placeholder='Confirmar senha' type='password' required error={touched.confirmPassword && errors.confirmPassword} />)} />
            <Button color='teal' fluid type='submit'>Salvar</Button>
          </Form>
        </Segment>
      )}
    </Formik>
  )
}

export default connect(
  store => { return { username: store.user.username } },
  dispatch => {
    return {
      setMessage: message => dispatch({ type: 'UPDATE', data: { message } }),
      setForm: form => dispatch({ type: 'UPDATE', data: { form } })
    }
  }
)(RecoverPassword)
