import React from 'react'
import Auth from '@aws-amplify/auth'
import { connect } from 'react-redux'
import { Button, Form, Segment } from 'semantic-ui-react'
import { Formik, Field } from 'formik'
import * as yup from 'yup'

const schema = yup.object().shape({
  username: yup.string().email().required()
})

function Recover({ username, setUsername, setForm, setMessage }) {
  async function onSubmit(values, actions) {
    try {
      await Auth.forgotPassword(values.username)
      setMessage(null)
      setUsername(values.username)
      setForm('recoverPassword')
    } catch (err) {
      if (err.code == 'UserNotFoundException') {
        setMessage(['negative', 'Conta não cadastrada.'])
      } else {
        setMessage(['negative', err.code])
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <Formik initialValues={{ username }} validationSchema={schema} onSubmit={onSubmit}>
      {({ isSubmitting, handleReset, handleSubmit }) => (
        <Segment raised>
          <Form loading={isSubmitting} onReset={handleReset} onSubmit={handleSubmit}>
            <Field name='username' render={({ field, form: { errors, touched } }) => (<Form.Input {...field} fluid type='email' icon='mail' iconPosition='left' placeholder='E-mail' required error={!!(touched.username && errors.username)} />)} />
            <Button color='teal' fluid type='submit'>Avançar</Button>
          </Form>
        </Segment>
      )}
    </Formik>
  )
}

export default connect(
  store => { return { username: store.user.username } },
  dispatch => {
    return {
      setMessage: message => dispatch({ type: 'UPDATE', data: { message } }),
      setForm: form => dispatch({ type: 'UPDATE', data: { form } }),
      setUsername: username => dispatch({ type: 'user/UPDATE', user: { username } }),
    }
  }
)(Recover)
