import React from 'react'
import { connect } from 'react-redux'
import { ApolloContext } from 'react-apollo'
import qs from 'query-string'
import gql from 'graphql-tag'
import { Loader, Grid, Header, Message } from 'semantic-ui-react'

import Logo from '../logo'
import FormLogin from './login'
import FormRecover from './recover'
import FormRecoverPassword from './recover_password'
import FormRedirect from './redirect'
import FormResetPassword from './reset_password'
import FormSignedIn from './signed_in'

class Page extends React.Component {
  constructor(props) {
    super(props)
    if (process.browser) {
      const { location, setForm, setMessage } = props

      const query = qs.parse(location.search)
      const env = query.env || localStorage.getItem('env')
      const redirect = query.redirect || sessionStorage.getItem('redirect')
      const method = query.method || sessionStorage.getItem('method') || 'POST'

      if (env) {
        localStorage.setItem('env', env)
      } else {
        setMessage(['negative', 'env não definido.'])
        setForm('error')
      }

      if (redirect) {
        sessionStorage.setItem('redirect', redirect)
      } else {
        setMessage(['negative', 'redirect não definido.'])
        setForm('error')
      }

      if (method) {
        sessionStorage.setItem('method', method)
      }
    }
  }

  async componentDidMount() {
    const { setForm, setUsername, setMessage } = this.props
    const { client } = this.context
    const env = localStorage.getItem('env')
    const redirect = sessionStorage.getItem('redirect')

    if (env && redirect) {
      if (!localStorage.getItem('token')) {
        setForm('login')
      } else {
        try {
          const { data: { user } } = await client.query({
            query: gql`{ user { environmentId credentialId } }`
          })

          if (user && user.environmentId == env) {
            if (user && user.credentialId) {
              const { data: { credential } } = await client.query({
                query: gql`query Credential($id: UUID!) { credential(id:$id) { login } }`,
                variables: { id: user.credentialId }
              })

              if (credential) {
                setUsername(credential.login)
                setForm('signedIn')
              } else {
                setMessage(['negative', 'Conta desativada.'])
                localStorage.removeItem('token')
                setForm('login')
              }
            } else {
              setMessage(['negative', 'Conta desativada.'])
              localStorage.removeItem('token')
              setForm('login')
            }
          } else {
            localStorage.removeItem('token')
            this.props.setForm('login')
          }

        } catch (e) {
          if (e.toString().indexOf('Failed to fetch') < 0) {
            localStorage.removeItem('token')
            setForm('login')
          }
        }
      }
    }
  }

  render() {
    return (
      <Grid textAlign='center' style={{ height: '100vh' }}>
        <Grid.Column style={{ maxWidth: 450, margin: '0 10px' }}>
          <br />
          <Header as='h2' textAlign='center'>
            <center>
              <Logo />
            </center>
          </Header>

          {this.props.message &&
            <Message
              positive={this.props.message[0] == 'positive'}
              negative={this.props.message[0] == 'negative'}
              warning={this.props.message[0] == 'warning'}>
              {this.props.message[1]}
            </Message>
          }

          {{
            loading: <Loader active />,
            signedIn: <FormSignedIn />,
            login: <FormLogin />,
            resetPassword: <FormResetPassword />,
            recover: <FormRecover />,
            recoverPassword: <FormRecoverPassword />,
            redirect: <FormRedirect />,
            error: <></>
          }[this.props.form]}

        </Grid.Column>
      </Grid>
    )
  }
}

Page.contextType = ApolloContext

export default connect(
  store => { return { ...store.global } },
  dispatch => {
    return {
      setForm: form => dispatch({ type: 'UPDATE', data: { form } }),
      setMessage: message => dispatch({ type: 'UPDATE', data: { message } }),
      setUsername: username => dispatch({ type: 'user/UPDATE', user: { username } })
    }
  }
)(Page)
