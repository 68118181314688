import React from 'react'
import Layout from '../components/layout'
import Login from '../components/login'

export default function Page(props) {
  return (
    <Layout {...props}>
      {({ location }) => (
        <Login location={location} />
      )}
    </Layout>
  )
}
